import { Link, Typography } from "@mui/material";
import { useActor } from "@xstate/react";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import FullScreenLoader from "src/components/FullScreenLoader";
import HomePageText from "src/components/HomePageText";
import View from "src/components/View";
import { useApp } from "src/contexts/AppContext";
import withProtected from "src/hoc/withProtected";
import { AppTag } from "src/machines/appMachine";
import { NextPageWithLayout } from "./_app";
import { Trans, useTranslation } from "react-i18next";
import { ACCOUNT } from "src/i18n/constants/namespaces";

const Dashboard: NextPageWithLayout = () => {
  const { t: getTranslation, i18n } = useTranslation([ACCOUNT]);
  const router = useRouter();
  const { appService } = useApp();
  const [appState, send] = useActor(appService);

  const {
    auth,
    userFirstName,
    agencyUserDetails,
    artistHomePageDetails,
    cdHomePageDetails,
    cdCommentNotifications,
    isFirstDayOnAltai,
    userDetailsLoaded,
  } = appState.context;

  const [geeting, setGreeting] = useState("");

  const MINUTE_MS = 60000;
  const artistEssentialsGuideUrl = i18n.resolvedLanguage?.startsWith("es")
    ? process.env.NEXT_PUBLIC_ALTAI_ESSENTIALS_GUIDE?.replace("en-us", "es")
    : i18n.resolvedLanguage === "en-AU"
    ? process.env.NEXT_PUBLIC_ALTAI_ESSENTIALS_GUIDE?.replace("en-us", "en-au")
    : process.env.NEXT_PUBLIC_ALTAI_ESSENTIALS_GUIDE;

  useEffect(() => {
    getGreeting(userFirstName);
    const interval = setInterval(() => {
      getGreeting(userFirstName);
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, [userFirstName]);

  useEffect(() => {
    send("UPDATE_HOME_PAGE_TEXT");
  }, []);

  useEffect(() => {
    if (!router.isReady) return;
    const customToken = (router.query.customToken as string | null) ?? null;
    if (customToken !== null && customToken !== "") {
      send({ type: "INIT_LOGIN_WITH_CUSTOM_TOKEN", customToken: customToken });
    }
  }, [router.isReady, router.query]);

  const getGreeting = (userName: string) => {
    var date = new Date();
    var time = date.getHours();

    setGreeting(
      getTranslation("loggedInDashboad.greeting", {
        context: time < 12 ? "morning" : time < 18 ? "afternoon" : "evening",
        userFirstName: userName,
        ns: ACCOUNT,
      })
    );
  };

  return (
    <>
      <Head>
        <title>
          {getTranslation("loggedInDashboad.headTitle", {
            ns: ACCOUNT,
          })}
        </title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <View>
        <View.Header variant="thin" />
        <View.Body>
          <FullScreenLoader loading={appState.hasTag(AppTag.loading)} />
          {userDetailsLoaded && (
            <>
              {!isFirstDayOnAltai && (
                <Typography variant="h1" sx={{ mb: 2, mt: "40px!important" }}>
                  <HomePageText>{geeting}</HomePageText>
                </Typography>
              )}
              {isFirstDayOnAltai && (
                <>
                  <Typography variant="h1" sx={{ mb: 2, mt: "40px!important" }}>
                    <HomePageText>
                      {getTranslation("loggedInDashboad.greeting", {
                        context: "firstDayOnAltai",
                        userFirstName: userFirstName,
                        ns: ACCOUNT,
                      })}
                    </HomePageText>
                  </Typography>

                  {/* -------------------------------- */}
                  {/* Agent Text For First Day Visit */}
                  {/* -------------------------------- */}

                  {auth.userType === "Agent" && (
                    <>
                      {agencyUserDetails &&
                        agencyUserDetails?.activeCastingBriefCount == 0 && (
                          <Typography variant="h1" sx={{ mb: 2 }}>
                            <HomePageText>
                              <Trans
                                i18nKey="loggedInDashboad.firstDayTask"
                                context="agentZeroBriefs"
                                components={{
                                  rosterLink: (
                                    <Link
                                      color="secondary"
                                      underline="none"
                                      href="#"
                                      onClick={(event) => {
                                        event.preventDefault();
                                        router.push("/artist-roster");
                                      }}
                                    />
                                  ),
                                }}
                                ns={ACCOUNT}
                              />
                            </HomePageText>
                          </Typography>
                        )}
                      {agencyUserDetails &&
                        agencyUserDetails?.activeCastingBriefCount > 0 && (
                          <Typography variant="h1" sx={{ mb: 2 }}>
                            <HomePageText>
                              <Trans
                                i18nKey="loggedInDashboad.firstDayTask"
                                context="agentSomeBriefs"
                                components={{
                                  briefsLink: (
                                    <Link
                                      color="secondary"
                                      underline="none"
                                      href="#"
                                      onClick={(event) => {
                                        event.preventDefault();
                                        router.push("/casting-briefs");
                                      }}
                                    />
                                  ),
                                }}
                                ns={ACCOUNT}
                              />
                            </HomePageText>
                          </Typography>
                        )}
                      <Typography variant="h1" sx={{ mb: 2 }}>
                        <HomePageText>
                          <Trans
                            i18nKey="loggedInDashboad.anyQuestionsVisitAltaiSupport"
                            components={{
                              supportLink: (
                                <Link
                                  color="secondary"
                                  underline="none"
                                  href={
                                    process.env
                                      .NEXT_PUBLIC_ZENDESK_HELPCENTER_AGENT
                                  }
                                  target="_blank"
                                />
                              ),
                            }}
                            ns={ACCOUNT}
                          />
                        </HomePageText>
                      </Typography>
                    </>
                  )}

                  {/* -------------------------------- */}
                  {/* Artist Text For First Day Visit */}
                  {/* -------------------------------- */}

                  {auth.userType === "Artist" && (
                    <>
                      <Typography variant="h1" sx={{ mb: 2 }}>
                        <HomePageText>
                          <Trans
                            i18nKey="loggedInDashboad.firstDayTask"
                            context="artistSiteTour"
                            components={{
                              tourLink: (
                                <Link
                                  color="secondary"
                                  underline="none"
                                  href={artistEssentialsGuideUrl}
                                  target="_blank"
                                />
                              ),
                            }}
                            ns={ACCOUNT}
                          />
                        </HomePageText>
                      </Typography>

                      <Typography variant="h1" sx={{ mb: 2 }}>
                        <HomePageText>
                          <Trans
                            i18nKey="loggedInDashboad.firstDayTask"
                            context="artistProfile"
                            components={{
                              profileLink: (
                                <Link
                                  color="secondary"
                                  underline="none"
                                  href="#"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    router.push("/edit-profile");
                                  }}
                                />
                              ),
                            }}
                            ns={ACCOUNT}
                          />
                        </HomePageText>
                      </Typography>

                      <Typography variant="h1" sx={{ mb: 2 }}>
                        <HomePageText>
                          <Trans
                            i18nKey="loggedInDashboad.anyQuestionsVisitAltaiSupport"
                            components={{
                              supportLink: (
                                <Link
                                  color="secondary"
                                  underline="none"
                                  href={
                                    process.env
                                      .NEXT_PUBLIC_ZENDESK_HELPCENTER_ARTIST
                                  }
                                  target="_blank"
                                />
                              ),
                            }}
                            ns={ACCOUNT}
                          />
                        </HomePageText>
                      </Typography>
                    </>
                  )}

                  {/* -------------------------------- */}
                  {/* CD Text For First Day Visit */}
                  {/* -------------------------------- */}

                  {auth.userType === "CastingDirector" &&
                    (!auth.isTeamAdmin ||
                      (auth.isTeamAdmin &&
                        cdHomePageDetails &&
                        cdHomePageDetails?.projectCount === 0)) && (
                      <>
                        {cdHomePageDetails?.projectCount === 0 && (
                          <>
                            <Typography variant="h1" sx={{ mb: 2 }}>
                              <HomePageText>
                                {/* TODO - put this text back in once agencies are live */}
                                {/* There’s lots to explore, but before you get
                                going, we recommend you and your team start
                                building your{" "}
                                <Link
                                  color="secondary"
                                  underline="none"
                                  href="#"
                                  onClick={() => {
                                    router.push("/lists/agency");
                                  }}
                                >
                                  Agency Lists
                                </Link>
                                . */}
                                <Trans
                                  i18nKey="loggedInDashboad.firstDayTask"
                                  context="castingDirectorZeroProjects"
                                  components={{
                                    agencyListLink: (
                                      <Link
                                        color="secondary"
                                        underline="none"
                                        href="#"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          router.push("/lists/agency");
                                        }}
                                      />
                                    ),
                                  }}
                                  ns={ACCOUNT}
                                />
                              </HomePageText>
                            </Typography>

                            <Typography variant="h1" sx={{ mb: 2 }}>
                              <HomePageText>
                                {getTranslation(
                                  "loggedInDashboad.homePageText",
                                  {
                                    context: "helpsBriefingOutFirstProject",
                                    ns: ACCOUNT,
                                  }
                                )}
                              </HomePageText>
                            </Typography>
                          </>
                        )}

                        {cdHomePageDetails &&
                          cdHomePageDetails?.projectCount > 0 && (
                            <Typography variant="h1" sx={{ mb: 2 }}>
                              <HomePageText>
                                {/* TODO - put this text back in once agencies are live */}
                                {/* There’s lots to explore, but before you get
                                going, we recommend checking out your{" "}
                                <Link
                                  color="secondary"
                                  underline="none"
                                  href="#"
                                  onClick={() => {
                                    router.push("/lists/agency");
                                  }}
                                >
                                  Agency Lists
                                </Link>{" "} */}
                                <Trans
                                  i18nKey="loggedInDashboad.firstDayTask"
                                  context="castingDirectorSomeProjects"
                                  components={{
                                    agencyListLink: (
                                      <Link
                                        color="secondary"
                                        underline="none"
                                        href="#"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          router.push("/lists/agency");
                                        }}
                                      />
                                    ),
                                    projectsLink: (
                                      <Link
                                        color="secondary"
                                        underline="none"
                                        href="#"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          router.push("/projects");
                                        }}
                                      />
                                    ),
                                  }}
                                  ns={ACCOUNT}
                                />
                              </HomePageText>
                            </Typography>
                          )}

                        <Typography variant="h1" sx={{ mb: 2 }}>
                          <HomePageText>
                            <Trans
                              i18nKey="loggedInDashboad.anyQuestionsVisitAltaiSupport"
                              components={{
                                supportLink: (
                                  <Link
                                    color="secondary"
                                    underline="none"
                                    href={
                                      process.env
                                        .NEXT_PUBLIC_ZENDESK_HELPCENTER_CASTINGDIRECTOR
                                    }
                                    target="_blank"
                                  />
                                ),
                              }}
                              ns={ACCOUNT}
                            />
                          </HomePageText>
                        </Typography>
                      </>
                    )}

                  {auth.userType === "CastingDirector" &&
                    auth.isTeamAdmin &&
                    cdHomePageDetails &&
                    cdHomePageDetails?.projectCount > 0 && (
                      <>
                        {/* -------------------------------- */}
                        {/* CD Text First Day Visit for Admins - If the office has 1 active projects */}
                        {/* -------------------------------- */}

                        {cdHomePageDetails.activeProjectCount === 1 && (
                          <>
                            <Typography variant="h1" sx={{ mb: 2 }}>
                              <HomePageText>
                                <Trans
                                  i18nKey="loggedInDashboad.homePageText"
                                  context="castingDirectorOneProject"
                                  values={{
                                    mostRecentProjectName:
                                      cdHomePageDetails.mostRecentProjectName,
                                  }}
                                  components={{
                                    projectLink: (
                                      <Link
                                        color="secondary"
                                        underline="none"
                                        href="#"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          router.push({
                                            pathname: "/projects/[projectId]",
                                            query: {
                                              projectId:
                                                cdHomePageDetails.mostRecentProjectId,
                                            },
                                          });
                                        }}
                                      />
                                    ),
                                  }}
                                  ns={ACCOUNT}
                                />
                              </HomePageText>
                            </Typography>
                            <Typography variant="h1" sx={{ mb: 2 }}>
                              <HomePageText>
                                {getTranslation(
                                  "loggedInDashboad.homePageText",
                                  {
                                    context: "unreadNotification",
                                    count:
                                      cdHomePageDetails.unreadNotificationCount,
                                    ns: ACCOUNT,
                                  }
                                )}
                              </HomePageText>
                            </Typography>
                          </>
                        )}

                        {/* -------------------------------- */}
                        {/* CD Text First Day Visit for Admins - If the office has more than 1 active projects */}
                        {/* -------------------------------- */}

                        {cdHomePageDetails.activeProjectCount > 1 && (
                          <>
                            <Typography variant="h1" sx={{ mb: 2 }}>
                              <HomePageText>
                                <Trans
                                  i18nKey="loggedInDashboad.homePageText"
                                  context="castingDirectorManyProjects"
                                  values={{
                                    mostRecentProjectName:
                                      cdHomePageDetails.mostRecentProjectName,
                                    secondMostRecentProjectName:
                                      cdHomePageDetails.secondMostRecentProjectName,
                                  }}
                                  components={{
                                    mostRecentProjectLink: (
                                      <Link
                                        color="secondary"
                                        underline="none"
                                        href="#"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          router.push({
                                            pathname: "/projects/[projectId]",
                                            query: {
                                              projectId:
                                                cdHomePageDetails.mostRecentProjectId,
                                            },
                                          });
                                        }}
                                      />
                                    ),
                                    secondMostRecentProjectLink: (
                                      <Link
                                        color="secondary"
                                        underline="none"
                                        href="#"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          router.push({
                                            pathname: "/projects/[projectId]",
                                            query: {
                                              projectId:
                                                cdHomePageDetails.secondMostRecentProjectId,
                                            },
                                          });
                                        }}
                                      />
                                    ),
                                  }}
                                  ns={ACCOUNT}
                                />
                              </HomePageText>
                            </Typography>
                            <Typography variant="h1" sx={{ mb: 2 }}>
                              <HomePageText>
                                {getTranslation(
                                  "loggedInDashboad.homePageText",
                                  {
                                    context: "unreadNotification",
                                    count:
                                      cdHomePageDetails.unreadNotificationCount,
                                    ns: ACCOUNT,
                                  }
                                )}
                              </HomePageText>
                            </Typography>
                          </>
                        )}

                        <Typography variant="h1" sx={{ mb: 2 }}>
                          <HomePageText>
                            <Trans
                              i18nKey="loggedInDashboad.homePageText"
                              context="castingDirectorCalendarSession"
                              count={cdHomePageDetails.calendarItemCount}
                              components={{
                                calendarLink: (
                                  <Link
                                    color="secondary"
                                    underline="none"
                                    href="#"
                                    onClick={(event) => {
                                      event.preventDefault();
                                      router.push("/calendar");
                                    }}
                                  />
                                ),
                              }}
                              ns={ACCOUNT}
                            />
                          </HomePageText>
                        </Typography>
                      </>
                    )}
                </>
              )}
              {!isFirstDayOnAltai && (
                <>
                  {/* -------------------------------- */}
                  {/* Agent Text Day to Day Visit */}
                  {/* -------------------------------- */}

                  {auth.userType === "Agent" && agencyUserDetails && (
                    <>
                      <Typography variant="h1" sx={{ mb: 2 }}>
                        <HomePageText>
                          <Trans
                            i18nKey="loggedInDashboad.homePageText"
                            context="agentNewBriefs"
                            count={agencyUserDetails.newCastingBriefCount}
                            components={{
                              briefsLink: (
                                <Link
                                  color="secondary"
                                  underline="none"
                                  href="#"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    router.push("/casting-briefs");
                                  }}
                                />
                              ),
                            }}
                            ns={ACCOUNT}
                          />
                        </HomePageText>
                      </Typography>
                      <Typography variant="h1" sx={{ mb: 2 }}>
                        <HomePageText>
                          <Trans
                            i18nKey="loggedInDashboad.homePageText"
                            context="agentStatusReportUpdate"
                            count={agencyUserDetails.newStatusReportUpdateCount}
                            components={{
                              statusReportLink: (
                                <Link
                                  color="secondary"
                                  underline="none"
                                  href="#"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    router.push("/status-report");
                                  }}
                                />
                              ),
                            }}
                            ns={ACCOUNT}
                          />
                        </HomePageText>
                      </Typography>
                      <Typography variant="h1" sx={{ mb: 2 }}>
                        <HomePageText>
                          <Trans
                            i18nKey="loggedInDashboad.homePageText"
                            context="agentCaledarItems"
                            count={agencyUserDetails.calendarItemCount}
                            components={{
                              calendarLink: (
                                <Link
                                  color="secondary"
                                  underline="none"
                                  href="#"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    router.push("/calendar");
                                  }}
                                />
                              ),
                            }}
                            ns={ACCOUNT}
                          />
                        </HomePageText>
                      </Typography>
                    </>
                  )}

                  {/* -------------------------------- */}
                  {/* Artist Text Day to Day Visit */}
                  {/* -------------------------------- */}

                  {auth.userType === "Artist" && artistHomePageDetails && (
                    <>
                      {/* -------------------------------- */}
                      {/* Artist Text Day to Day Visit - Profile Incomplete */}
                      {/* -------------------------------- */}
                      {!artistHomePageDetails.isProfileCompleted && (
                        <>
                          <Typography variant="h1" sx={{ mb: 2 }}>
                            <HomePageText>
                              <Trans
                                i18nKey="loggedInDashboad.homePageText"
                                context="artistIncompleteProfile"
                                components={{
                                  profileLink: (
                                    <Link
                                      color="secondary"
                                      underline="none"
                                      href="#"
                                      onClick={(event) => {
                                        event.preventDefault();
                                        router.push("/edit-profile");
                                      }}
                                    />
                                  ),
                                }}
                                ns={ACCOUNT}
                              />
                            </HomePageText>
                          </Typography>

                          <Typography variant="h1" sx={{ mb: 2 }}>
                            <HomePageText>
                              <Trans
                                i18nKey="loggedInDashboad.homePageText"
                                context="artistEssentialsGuideIncompleteProfile"
                                components={{
                                  essentialsGuideLink: (
                                    <Link
                                      color="secondary"
                                      underline="none"
                                      href={artistEssentialsGuideUrl}
                                      target="_blank"
                                    />
                                  ),
                                }}
                                ns={ACCOUNT}
                              />
                            </HomePageText>
                          </Typography>

                          <Typography variant="h1" sx={{ mb: 2 }}>
                            <HomePageText>
                              <Trans
                                i18nKey="loggedInDashboad.anyQuestionsVisitAltaiSupport"
                                components={{
                                  supportLink: (
                                    <Link
                                      color="secondary"
                                      underline="none"
                                      href={
                                        process.env
                                          .NEXT_PUBLIC_ZENDESK_HELPCENTER_ARTIST
                                      }
                                      target="_blank"
                                    />
                                  ),
                                }}
                                ns={ACCOUNT}
                              />
                            </HomePageText>
                          </Typography>
                        </>
                      )}

                      {/* -------------------------------- */}
                      {/* Artist Text Day to Day Visit - Profile Complete and, on lite tier and Represented / Freelance */}
                      {/* -------------------------------- */}
                      {artistHomePageDetails.isProfileCompleted &&
                        artistHomePageDetails.isLiteTierArtist && (
                          <>
                            <Typography variant="h1" sx={{ mb: 2 }}>
                              <HomePageText>
                                <Trans
                                  i18nKey="loggedInDashboad.homePageText"
                                  context="artistLiteTier"
                                  components={{
                                    profileLink: (
                                      <Link
                                        color="secondary"
                                        underline="none"
                                        href="#"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          router.push(
                                            `/view-profile/${artistHomePageDetails.profileCode}`
                                          );
                                        }}
                                      />
                                    ),
                                    proTierProfileLink: (
                                      <Link
                                        color="secondary"
                                        underline="none"
                                        href="#"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          router.push(`/edit-profile`);
                                        }}
                                      />
                                    ),
                                    featureListLink: (
                                      <Link
                                        color="secondary"
                                        underline="none"
                                        href="#"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          router.push(
                                            `/account-details/subscription`
                                          );
                                        }}
                                      />
                                    ),
                                  }}
                                  ns={ACCOUNT}
                                />
                              </HomePageText>
                            </Typography>
                          </>
                        )}

                      {/* -------------------------------- */}
                      {/* Artist Text Day to Day Visit - Profile Complete and Represented */}
                      {/* -------------------------------- */}
                      {artistHomePageDetails.isProfileCompleted &&
                        artistHomePageDetails.isRepresented && (
                          <>
                            <Typography variant="h1" sx={{ mb: 2 }}>
                              <HomePageText>
                                <Trans
                                  i18nKey="loggedInDashboad.homePageText"
                                  context="artistStatusReportUpdate"
                                  count={
                                    artistHomePageDetails.newStatusReportUpdateCount
                                  }
                                  components={{
                                    statusReportLink: (
                                      <Link
                                        color="secondary"
                                        underline="none"
                                        href="#"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          router.push("/status-report");
                                        }}
                                      />
                                    ),
                                  }}
                                  ns={ACCOUNT}
                                />
                              </HomePageText>
                            </Typography>
                            <Typography variant="h1" sx={{ mb: 2 }}>
                              <HomePageText>
                                <Trans
                                  i18nKey="loggedInDashboad.homePageText"
                                  context="artistCaledarItems"
                                  count={
                                    artistHomePageDetails.calendarItemCount
                                  }
                                  components={{
                                    calendarLink: (
                                      <Link
                                        color="secondary"
                                        underline="none"
                                        href="#"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          router.push("/calendar");
                                        }}
                                      />
                                    ),
                                  }}
                                  ns={ACCOUNT}
                                />
                              </HomePageText>
                            </Typography>
                          </>
                        )}

                      {/* -------------------------------- */}
                      {/* Artist Text Day to Day Visit - Profile Complete and Freelance */}
                      {/* -------------------------------- */}
                      {artistHomePageDetails.isProfileCompleted &&
                        !artistHomePageDetails.isRepresented && (
                          <>
                            <Typography variant="h1" sx={{ mb: 2 }}>
                              <HomePageText>
                                {getTranslation(
                                  "loggedInDashboad.homePageText",
                                  {
                                    context: "artistProfileUpToDate",
                                    ns: ACCOUNT,
                                  }
                                )}
                              </HomePageText>
                            </Typography>

                            <Typography variant="h1" sx={{ mb: 2 }}>
                              <HomePageText>
                                <Trans
                                  i18nKey="loggedInDashboad.homePageText"
                                  context="artistEssentialsGuide"
                                  components={{
                                    essentialsGuideLink: (
                                      <Link
                                        color="secondary"
                                        underline="none"
                                        href={artistEssentialsGuideUrl}
                                        target="_blank"
                                      />
                                    ),
                                  }}
                                  ns={ACCOUNT}
                                />
                              </HomePageText>
                            </Typography>

                            <Typography variant="h1" sx={{ mb: 2 }}>
                              <HomePageText>
                                <Trans
                                  i18nKey="loggedInDashboad.anyQuestionsVisitAltaiSupport"
                                  components={{
                                    supportLink: (
                                      <Link
                                        color="secondary"
                                        underline="none"
                                        href={
                                          process.env
                                            .NEXT_PUBLIC_ZENDESK_HELPCENTER_ARTIST
                                        }
                                        target="_blank"
                                      />
                                    ),
                                  }}
                                  ns={ACCOUNT}
                                />
                              </HomePageText>
                            </Typography>
                          </>
                        )}
                    </>
                  )}

                  {/* -------------------------------- */}
                  {/* CD Text Day to Day Visit */}
                  {/* -------------------------------- */}

                  {auth.userType === "CastingDirector" && cdHomePageDetails && (
                    <>
                      {/* -------------------------------- */}
                      {/* CD Text Day to Day Visit - If the office has no active projects */}
                      {/* -------------------------------- */}

                      {cdHomePageDetails.activeProjectCount === 0 && (
                        <Typography variant="h1" sx={{ mb: 2 }}>
                          <HomePageText>
                            {getTranslation("loggedInDashboad.homePageText", {
                              context: "castingDirectorZeroProjects",
                              count: cdHomePageDetails.unreadNotificationCount,
                              ns: ACCOUNT,
                            })}
                          </HomePageText>
                        </Typography>
                      )}

                      {/* -------------------------------- */}
                      {/* CD Text Day to Day Visit - If the office has 1 active projects */}
                      {/* -------------------------------- */}

                      {cdHomePageDetails.activeProjectCount === 1 && (
                        <>
                          <Typography variant="h1" sx={{ mb: 2 }}>
                            <HomePageText>
                              <Trans
                                i18nKey="loggedInDashboad.homePageText"
                                context="castingDirectorOneProject"
                                values={{
                                  mostRecentProjectName:
                                    cdHomePageDetails.mostRecentProjectName,
                                }}
                                components={{
                                  projectLink: (
                                    <Link
                                      color="secondary"
                                      underline="none"
                                      href="#"
                                      onClick={(event) => {
                                        event.preventDefault();
                                        router.push({
                                          pathname: "/projects/[projectId]",
                                          query: {
                                            projectId:
                                              cdHomePageDetails.mostRecentProjectId,
                                          },
                                        });
                                      }}
                                    />
                                  ),
                                }}
                                ns={ACCOUNT}
                              />
                            </HomePageText>
                          </Typography>
                          <Typography variant="h1" sx={{ mb: 2 }}>
                            <HomePageText>
                              {getTranslation("loggedInDashboad.homePageText", {
                                context: "unreadNotification",
                                count:
                                  cdHomePageDetails.unreadNotificationCount,
                                ns: ACCOUNT,
                              })}
                            </HomePageText>
                          </Typography>
                        </>
                      )}

                      {/* -------------------------------- */}
                      {/* CD Text Day to Day Visit - If the office has more than 1 active projects */}
                      {/* -------------------------------- */}

                      {cdHomePageDetails.activeProjectCount > 1 && (
                        <>
                          <Typography variant="h1" sx={{ mb: 2 }}>
                            <HomePageText>
                              <Trans
                                i18nKey="loggedInDashboad.homePageText"
                                context="castingDirectorManyProjects"
                                values={{
                                  mostRecentProjectName:
                                    cdHomePageDetails.mostRecentProjectName,
                                  secondMostRecentProjectName:
                                    cdHomePageDetails.secondMostRecentProjectName,
                                }}
                                components={{
                                  mostRecentProjectLink: (
                                    <Link
                                      color="secondary"
                                      underline="none"
                                      href="#"
                                      onClick={(event) => {
                                        event.preventDefault();
                                        router.push({
                                          pathname: "/projects/[projectId]",
                                          query: {
                                            projectId:
                                              cdHomePageDetails.mostRecentProjectId,
                                          },
                                        });
                                      }}
                                    />
                                  ),
                                  secondMostRecentProjectLink: (
                                    <Link
                                      color="secondary"
                                      underline="none"
                                      href="#"
                                      onClick={(event) => {
                                        event.preventDefault();
                                        router.push({
                                          pathname: "/projects/[projectId]",
                                          query: {
                                            projectId:
                                              cdHomePageDetails.secondMostRecentProjectId,
                                          },
                                        });
                                      }}
                                    />
                                  ),
                                }}
                                ns={ACCOUNT}
                              />
                            </HomePageText>
                          </Typography>
                          <Typography variant="h1" sx={{ mb: 2 }}>
                            <HomePageText>
                              {getTranslation("loggedInDashboad.homePageText", {
                                context: "unreadNotification",
                                count:
                                  cdHomePageDetails.unreadNotificationCount,
                                ns: ACCOUNT,
                              })}
                            </HomePageText>
                          </Typography>
                        </>
                      )}

                      <Typography variant="h1" sx={{ mb: 2 }}>
                        <HomePageText>
                          <Trans
                            i18nKey="loggedInDashboad.homePageText"
                            context="castingDirectorCalendarSession"
                            count={cdHomePageDetails.calendarItemCount}
                            components={{
                              calendarLink: (
                                <Link
                                  color="secondary"
                                  underline="none"
                                  href="#"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    router.push("/calendar");
                                  }}
                                />
                              ),
                            }}
                            ns={ACCOUNT}
                          />
                        </HomePageText>
                      </Typography>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </View.Body>
      </View>
    </>
  );
};

export default withProtected({ redirect: "/login" })(Dashboard);
